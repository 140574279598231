<template>
  <div>
    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      text
      border="left"
      type="info"
      v-if="showInfoStart"
    >
      Der Abschluss kann ab
      <DateValue :value="balance.startDate" long /> durchgeführt werden.
    </v-alert>
    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      text
      type="error"
      v-if="showWarningPending"
    >
      <v-row>
        <v-col class="grow"
          >Offene Absenzen (1. Spalte) sollten möglichst vor dem Abschluss
          bereinigt werden!<br />Andernfalls werden sie ins neue Semester
          übernommen.</v-col
        >
        <v-col class="shrink"
          ><v-btn depressed color="error" @click="hideWarningPending = true"
            >ist mir klar!</v-btn
          ></v-col
        >
      </v-row>
    </v-alert>
    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      text
      type="info"
      v-if="showWarningBalance"
    >
      <v-row>
        <v-col class="grow"
          >Der Abschluss kann nun vorgenommen werden.<br />Die Absenzen des
          {{ secondTerm ? "2. Semesters" : "1. Semesters" }} werden
          abgeschlossen und können nicht mehr verändert werden.
        </v-col>
        <v-col class="shrink"
          ><v-btn
            depressed
            color="info"
            @click="doBalance()"
            :loading="balancing"
            >jetzt durchführen</v-btn
          ></v-col
        >
      </v-row>
    </v-alert>

    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      text
      type="success"
      v-if="balance && balance.done"
    >
      <v-row>
        <v-col class="grow">
          Der Abschluss wurde am <DateValue :value="balance.date" long /> von
          <PersonName :value="balance.teacher" /> gemacht.<br />Die Daten des
          aktuellen Semesters müssen nun nach Evento kopiert werden.
          {{
            secondTerm
              ? "Fürs Jahreszeugnis werden die Werte der beiden Semester addiert."
              : ""
          }}
        </v-col>
        <v-col class="shrink">
          <v-dialog width="500" v-model="dialog" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" depressed v-bind="attrs" v-on="on">
                Daten kopieren
              </v-btn>
            </template>

            <v-card>
              <v-system-bar window>
                Semesterabschluss für Evento kopieren
                <v-spacer />
                <v-btn icon @click="dialog = false"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-system-bar>
              <v-card-text class="py-2">
                Der folgende Text muss nach Evento kopiert werden:
              </v-card-text>
              <v-card-text class="pb-0">
                <v-textarea
                  outlined
                  readonly
                  :value="absenceTable"
                ></v-textarea>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn text @click="dialog = false">abbrechen</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="copyAbsenceTable"
                  ><v-icon left>mdi-export</v-icon> in Zwischenablage</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-alert>
    <v-card outlined :loading="loading">
      <v-system-bar>Absenzenabschluss</v-system-bar>

      <v-data-table
        disable-pagination
        hide-default-footer
        :headers="headers"
        :items="items"
      >
        <template v-slot:header="">
          <thead>
            <tr>
              <th></th>
              <th class="text-center">offen</th>
              <th
                colspan="2"
                class="text-center"
                :class="{ 'white--text info': !secondTerm }"
              >
                1. Sem
              </th>
              <th
                colspan="2"
                class="text-center"
                :class="{ 'white--text info': secondTerm }"
              >
                2. Sem
              </th>
              <th colspan="2" class="text-center">Zeugnis</th>
            </tr>
          </thead>
        </template>

        <template v-slot:item.student="{ item }">
          <PersonItem small :value="item.student" />
        </template>
        <template v-slot:item.pendingLessons="{ item }">
          <HideZero
            class="text-subtitle-2"
            :value="item.pendingLessons"
          ></HideZero>
        </template>
        <template v-slot:item.first.excusedLessons="{ item }">
          <HideZero
            class="text-subtitle-2"
            :value="item.first.excusedLessons"
          ></HideZero>
        </template>
        <template v-slot:item.first.unexcusedLessons="{ item }">
          <HideZero
            class="text-subtitle-2"
            :value="item.first.unexcusedLessons"
          ></HideZero>
        </template>
        <template v-slot:item.second.excusedLessons="{ item }">
          <HideZero
            class="text-subtitle-2"
            :value="item.second.excusedLessons"
          ></HideZero>
        </template>
        <template v-slot:item.second.unexcusedLessons="{ item }">
          <HideZero
            class="text-subtitle-2"
            :value="item.second.unexcusedLessons"
          ></HideZero>
        </template>
        <template v-slot:item.sum.excusedLessons="{ item }">
          <HideZero
            class="text-subtitle-2"
            :value="item.first.excusedLessons + item.second.excusedLessons"
          ></HideZero>
        </template>
        <template v-slot:item.sum.unexcusedLessons="{ item }">
          <HideZero
            class="text-subtitle-2"
            :value="item.first.unexcusedLessons + item.second.unexcusedLessons"
          >
          </HideZero>
        </template>
        <template v-slot:footer
          ><TableFooter
            :items="items"
            label="Schüler:innen"
            labelSingular="Schüler:in"
        /></template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";
import PersonName from "common/components/PersonName.vue";
import TableFooter from "common/components/TableFooter.vue";
import { comparePeople } from "common/utils/people.js";
export default defineComponent({
  components: {
    DateValue,
    PersonItem,
    PersonName,
    TableFooter,
  },
  props: ["schoolClass"],
  data() {
    return {
      balances: [],
      balancing: false,
      dialog: false,
      headers: [
        {
          text: "Schüler:in",
          value: "student",
          sort: (a, b) => comparePeople(a, b),
          divider: true,
        },
        {
          text: "P",
          value: "pendingLessons",
          cellClass: "orange--text",
          align: "center",
          divider: true,
        },
        {
          text: "E",
          value: "first.excusedLessons",
          cellClass: "green--text",
          align: "center",
        },

        {
          text: "U",
          value: "first.unexcusedLessons",
          cellClass: "red--text",
          align: "center",
          divider: true,
        },
        {
          text: "E",
          value: "second.excusedLessons",
          cellClass: "green--text ",
          align: "center",
        },
        {
          text: "U",
          value: "second.unexcusedLessons",
          cellClass: "red--text ",
          align: "center",
          divider: true,
        },
        {
          text: "E",
          value: "sum.excusedLessons",
          cellClass: "green--text ",
          align: "center",
        },
        {
          text: "U",
          value: "sum.unexcusedLessons",
          cellClass: "red--text",
          align: "center",
        },
      ],
      items: [],
      loading: false,
      loadingBalances: false,
      firstTerm: null,
      secondTerm: null,
      hideWarningPending: false,
    };
  },
  computed: {
    absenceTable() {
      let result = "";
      for (const item of this.items) {
        result += item.student.officialName;
        result += "\t";
        if (this.secondTerm) {
          result += item.second.excusedLessons;
          result += "\t";
          result += item.second.unexcusedLessons;
        } else {
          result += item.first.excusedLessons;
          result += "\t";
          result += item.first.unexcusedLessons;
        }
        result += "\n";
      }
      return result;
    },
    balance() {
      return this.balances.find(
        (el) => el.schoolClass.id == this.schoolClass.id
      );
    },
    hasPending() {
      return this.items.some((el) => el.pendingLessons > 0);
    },
    showInfoStart() {
      return this.balance && !this.balance.open && !this.balance.done;
    },
    showWarningBalance() {
      return (
        (this.hideWarningPending || !this.hasPending) &&
        this.balance &&
        this.balance.open
      );
    },
    showWarningPending() {
      return (
        !this.hideWarningPending &&
        this.hasPending &&
        this.balance &&
        this.balance.open
      );
    },
  },
  methods: {
    async copyAbsenceTable() {
      try {
        await navigator.clipboard.writeText(this.absenceTable);
        this.$root.showSuccess(
          "<strong>in Zwischenablage kopiert:</strong><br>" + this.absenceTable
        );
      } catch (err) {
        this.$root.showError("Fehler beim Kopieren in die Zwischenablage");
      }
      this.dialog = false;
    },
    async doBalance() {
      if (
        await this.$root.confirm({
          message: `Der Abschluss kann nur ein Mal gemacht werden.<br>Soll der Abschluss nun durchgeführt werden? `,
          color: "info",
          icon: "mdi-scale-balance",
        })
      ) {
        this.balancing = true;
        await this.apiPost({
          resource: "absence/balance",
          data: {
            schoolClass: this.schoolClass.id,
          },
        });
        this.fetchData();
        this.balancing = false;
      }
    },
    async fetchBalances() {
      this.loadingBalances = true;
      this.balances = await this.apiList({
        resource: "absence/balance",
      });
      this.loadingBalances = false;
    },
    async fetchOverviews() {
      if (
        this.schoolClass &&
        this.schoolClass.id &&
        this.firstTerm &&
        this.firstTerm.id
      ) {
        this.loading = true;
        this.items = [];

        const itemsFirstTerm = await this.apiList({
          resource: "absence/overview",
          query: `schoolClass=${this.schoolClass.id}&term=${this.firstTerm.id}`,
        });
        this.items = itemsFirstTerm.map((item) => {
          return {
            student: item.student,
            pendingLessons: item.pendingLessons,
            //halfDays: el.halfDays,
            first: {
              absentLessons: item.absentLessons,
              excusedLessons: item.excusedLessons,
              //halfDays: item.halfDays,
              unexcusedLessons: item.unexcusedLessons,
            },
            second: {
              absentLessons: 0,
              excusedLessons: 0,
              unexcusedLessons: 0,
            },
          };
        });

        if (this.secondTerm) {
          const items = await this.apiList({
            resource: "absence/overview",
            query: `schoolClass=${this.schoolClass.id}&term=${this.secondTerm.id}`,
          });
          for (const item of items) {
            const el = this.items.find(
              (el) => el.student.id == item.student.id
            );
            el.pendingLessons = item.pendingLessons;
            el["second"] = {
              absentLessons: item.absentLessons,
              excusedLessons: item.excusedLessons,
              //halfDays: item.halfDays,
              unexcusedLessons: item.unexcusedLessons,
            };
          }
        }
        this.loading = false;
      }
    },
    async fetchData() {
      if (this.$_currentTerm.semester.id == 2) {
        this.secondTerm = this.$_currentTerm;
        const terms = await this.apiList({
          resource: "common/term",
          query: `offsetpast=-1`,
        });
        this.firstTerm = terms[0];
      } else {
        this.firstTerm = this.$_currentTerm;
      }

      await this.fetchBalances();
      await this.fetchOverviews();
    },
  },

  created() {
    this.fetchData();
  },
});
</script>
